

//
// ICON LIST
//

.icon-list{
	list-style: none;
	margin-left: 0;
	padding-left: 0;
}

.icon-list-double{
	display: flex;
	flex-flow: row wrap;

	.icon-list-item{
		flex: 1 1 50%;
		margin-bottom: 0.87em;
	}
}

.icon-list-item{
	display: flex;
}

.icon-list-icon{
	color: $headings-color;
	font-size: 1.67em;
	padding-right: 1em;
}

