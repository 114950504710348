
.mobile-nav-toggle-holder,
.main-nav-toggle{
  margin-right: -15px;
  z-index: 1100;
}

.mobile-nav-toggle,
.main-nav-toggle{

      svg {
        width: 80px;
        height: 60px;
        cursor: pointer;
        -webkit-transform: translate3d(0, 0, 0);
        -moz-transform: translate3d(0, 0, 0);
        -o-transform: translate3d(0, 0, 0);
        -ms-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
      }
      path {
        fill: none;
        -webkit-transition: stroke-dashoffset 0.5s cubic-bezier(0.25, -0.25, 0.75, 1.25), stroke-dasharray 0.5s cubic-bezier(0.25, -0.25, 0.75, 1.25);
        -moz-transition: stroke-dashoffset 0.5s cubic-bezier(0.25, -0.25, 0.75, 1.25), stroke-dasharray 0.5s cubic-bezier(0.25, -0.25, 0.75, 1.25);
        -o-transition: stroke-dashoffset 0.5s cubic-bezier(0.25, -0.25, 0.75, 1.25), stroke-dasharray 0.5s cubic-bezier(0.25, -0.25, 0.75, 1.25);
        -ms-transition: stroke-dashoffset 0.5s cubic-bezier(0.25, -0.25, 0.75, 1.25), stroke-dasharray 0.5s cubic-bezier(0.25, -0.25, 0.75, 1.25);
        transition: stroke-dashoffset 0.5s cubic-bezier(0.25, -0.25, 0.75, 1.25), stroke-dasharray 0.5s cubic-bezier(0.25, -0.25, 0.75, 1.25);
        stroke-width: 40px;
        stroke-linecap: round;
        stroke: $brand-primary;
        stroke-dashoffset: 0px;
      }
      path#top,
      path#bottom {
        stroke-dasharray: 240px 950px;
      }
      path#middle {
        stroke-dasharray: 240px 240px;
      }
      &.cross path#top,
      &.cross path#bottom {
        stroke-dashoffset: -650px;
        stroke-dashoffset: -650px;
      }
      &.cross path#middle {
        stroke-dashoffset: -115px;
        stroke-dasharray: 1px 220px;
      }
}

.main-nav-toggle{
  z-index: 2000;
}