html {
    font-size: 100%;
}

body {
    -webkit-font-smoothing: subpixel-antialiased;
    -moz-osx-font-smoothing: auto;
    line-height: 1.7;
    @include media-breakpoint-up(lg){
        line-height: 1.75;
    }
}

// mixin for light text on dark background font rendering
@mixin dark-bg {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.dark-bg{
    @include dark-bg
}

//
// REDEFINE TYPE BASED ON SCREEN WIDTH
// these are type styles... Bigger screens bigger base size

@media screen and (min-width: 1700px) {
    body {
        font-size: 120%;
    }
}

.serif {
    font-family: $font-family-serif;
}

.sans {
    font-family: $font-family-sans-serif;
}

.text-sans {
    font-family: $font-family-sans-serif;
}

.text-block-narrow {
    max-width: 60em;
    margin-left: auto;
    margin-right: auto;
}

.table{
    th, td{
        max-width: 18rem;
    }
}


h3 {}

p {
    font-weight: 400;
    letter-spacing: -0.035em;
     /* Use with caution */
     -moz-font-feature-settings: "kern";
     -moz-font-feature-settings: "kern=1";
     -ms-font-feature-settings: "kern";
     -o-font-feature-settings: "kern";
     -webkit-font-feature-settings: "kern";
     font-feature-settings: "kern";
     -webkit-font-smoothing: antialiased;
}

p.small{
    font-size: 0.9rem;
    line-height: 1.67;
}

.lead {
    font-family: $font-family-sans-serif;
    letter-spacing: -0.03em;
    line-height: 1.5;
    font-weight: 400;
    @include media-breakpoint-up(lg) {
        font-weight: 300;
        font-size: 1.5em;
    }
}

.lead-border {
    color: $brand-info;
    padding-bottom: 1em;
    border-bottom: 1px solid $gray-light;
}

p strong {
    font-weight: 500;
}


h1,
h2,
h3,
h4,
h5,
h6 {
    letter-spacing: -0.055em;
}

.text-white {
    color: #fff;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    -webkit-font-smoothing: antialiased;
    -webkit-hyphens: manual;
    -moz-hyphens: manual;
    -ms-hyphens: manual;
    hyphens: manual;
    text-rendering: optimizeLegibility;
    /* Use with caution */
    -moz-font-feature-settings: "kern";
    -moz-font-feature-settings: "kern=1";
    -ms-font-feature-settings: "kern";
    -o-font-feature-settings: "kern";
    -webkit-font-feature-settings: "kern";
    font-feature-settings: "kern";
    strong {
        color: $brand-primary;
        font-weight: 700;
    }
}

.balance-text{
    text-wrap: balance !important;  /* Apply (proposed) CSS style */
}

h1, h2, h3, h4{
    @include media-breakpoint-down(md){
        hyphens: auto;
        -webkit-hypens: auto;
        -moz-hyphens: auto;
    }
}

h1 {
    font-size: 2.4em;
    @include media-breakpoint-up(sm) {
        font-size: 3em;
        line-height: 1;
        letter-spacing: -0.05em;
    }
}

h2 {
    @include media-breakpoint-up(sm) {
        font-size: 2.6em;
    }
}

h3 {
    @include media-breakpoint-up(sm) {
        font-size: 2.2em;
    }
}

h4 {
    @include media-breakpoint-up(sm) {
        font-size: 1.8em;
    }
}

h5 {
    @include media-breakpoint-up(sm) {
        font-size: 1.4em;
    }
}

h6 {
    letter-spacing: -0.05em;
    font-size: 1em;
    small {
        font-weight: 500;
        text-transform: uppercase;
        opacity: 0.65;
        letter-spacing: -0.05em;
    }
}


.subtitle{
	color: $brand-info;
}

p.subtitle{
    line-height: 1.25;
}

.text-faded {
    opacity: 0.6;
}


.img-caption{
    padding: 0.33em 0;
    font-size: 0.8em;
}


 
@function set-bg-text-color($color) {
    @if (lightness($color) > 60) {
        @return #000000; // Lighter backgorund, return dark color
    }
    @else {
        @return #ffffff; // Darker background, return light color
    }
}

// BACKGROUND COLORED TEXT
// text with background in a nice way  **NEEDS WORK!!! ADDING COLOR LUMINOSITY CALCULATION
.text-bg-color {
	color: set-bg-text-color($brand-primary);
   	span[class*="bg-"]{
   		display: inline-block;
   		padding: 0.1em 0.2em;
   	}
}





//
// SOME CSS COLUMNS SETTINGS
//

.columns{
  @include media-breakpoint-up(lg){
    columns: 2;
    column-gap: $spacer * 1.5;
  }
}





//
// MEASURE UTILITY
//

$measure: 30em !default;
$measure-narrow: 20em !default;
$measure-wide: 34em !default;
$measure-ultra-wide: 40em !default;

/* Measure is limited to ~66 characters */
.measure {
  max-width: $measure;
}

/* Measure is limited to ~80 characters */
.measure-wide {
  max-width: $measure-wide;
}

/* Measure is limited to ~80 characters */
.measure-ultra-wide {
  max-width: $measure-ultra-wide;
}


/* Measure is limited to ~45 characters */
.measure-narrow {
  max-width: $measure-narrow;
}


//
//FONT WEIGHT
//

.normal { font-weight: normal; }
.b { font-weight: bold; }
.fw1 { font-weight: 100; }
.fw2 { font-weight: 200; }
.fw3 { font-weight: 300; }
.fw4 { font-weight: 400; }
.fw5 { font-weight: 500; }
.fw6 { font-weight: 600; }
.fw7 { font-weight: 700; }
.fw8 { font-weight: 800; }
.fw9 { font-weight: 900; }



