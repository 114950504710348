
// move this to global styles
body{
	//padding-top: $navbar-height;
}

// default post container styles
.post{
	@include media-breakpoint-up(md) {
		font-size: 1.1em;
	}
}


// The post contents width definition 
// has to be rem for constant width
$max-post-width: 46rem;
$max-element-width: $max-post-width + 18rem;

// info bar on top of post
$info-bar-height: 60px;

@mixin max-post-width{
	max-width: $max-post-width;
}


//
// POST HEADER STYLES
// start

.post-header{
	min-height: 85vh;
}


// Post Header figcaption defaults
.post-header-titles{
	bottom: 8%;
		@include media-breakpoint-up(lg){
			bottom: 20%;
	}
}

.post-header-title{
	font-size: 10vw;
	line-height: 1;
	@include media-breakpoint-up(lg){
		font-size: 5vw;
	}
}


//
// POST header rubriek title

.post-header-rubriek-title{
	font-size: 0.75em;
	text-transform: uppercase;
	font-weight: 400;
	letter-spacing: 0.2em;
	margin-bottom: 0.5em;
}

.NO-IE .post-header-subtitle-holder{
	display: none;
	@include media-breakpoint-up(xl){
		display: block;
		position: absolute;
		left: -5em;
		top: 96px;
		writing-mode: vertical-lr;
		white-space: nowrap;
	}
}

//
// CAT TAGS POST
// The Category and tags info at the top of the post

.post-cat-tags{
	background: $gray-lighter;
	display: flex; 
	align-items: center;
	z-index: 10; // stays on top
	@include media-breakpoint-up(lg) {
		height: $info-bar-height;
	}
}

.post-tags{
	flex: 0 0 auto;
	margin-left: 0;
	display: flex;
	margin-bottom: 0;
	.post-tag{
		font-size: 0.85em;
		color: $gray;
		margin-right: 2em;
		flex: 0 0 auto;
		list-style: square;
	}
	@include media-breakpoint-down(sm) {
		display: none;
	}
}

.post-category{
	margin-right: 1em;
	margin-left: auto;
	margin-bottom: 0;
	font-weight: 500;
}


.post-cat-link{
	width: $info-bar-height;
	height: $info-bar-height;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 0.5em 1em;
	color: #fff;
	background: $brand-primary;
	z-index: 5;
	&:hover,
	&:focus{
		color: #f5f5f5;
	}
}


//
// END CAT TAGS POST SECTION
//

//
// POST TITLES SECTION
//
.post-titles{
	max-width: $max-element-width;
	margin-left: auto;
	margin-right: auto;
	position: relative;
	z-index: 10;
	padding: 1rem;
	@include media-breakpoint-up(lg){
		padding: 8rem 0 2em;
	}
		// on smaller devices this title is always hidden
	@include media-breakpoint-down(sm) {
		display: none;
	}
}

.post-rubriek-title{
	color: $brand-primary;
	font-weight: 400;
}

.post-title{
	color: $headings-color;
	font-size: 6em;
	line-height: 0.95;
	letter-spacing: -0.065em;
	margin-bottom: 0.1em;
	strong{
		font-size: 0.5em;
		font-weight: 400;
		letter-spacing: -0.045em;
		color: $brand-info;
	}
}

.post-subtitle{
	color: lighten($brand-info, 55%);
	font-weight: 400;
	letter-spacing: -0.05em;
}

//
// END POST TITLES SECTION ****
//

.read-time{
	//position: absolute;
	//right: 1em;
	//top: 1em;
	font-size: 0.9em;
	color: $gray-light;
}



//
// MAIN EDITOR FILLED CONTENT STYLING
// Some styling for common elements inside the editor filled content


main{
	.btn{
		// on phones the buttons are always block
		@include media-breakpoint-down(sm) {
			display: block;
		}
	}
}
// first paragraph is lead
.story-full > p:nth-of-type(1){
	font-size: 1.35em;
	font-weight: 300;
	letter-spacing: -0.01em;
	font-family: $font-family-sans-serif;
	line-height: 1.5;
	margin-bottom: 3rem;
	&::first-letter {
		float: left;
		font-size: 3em; 
		line-height: 1em; 
		margin-top: -0.05em; 
		margin-bottom: -0.15em;
		padding-right: 0.05em; 
		padding-left: 0; 
		font-weight: 300;
		text-transform: uppercase;
	}
}


.intro-section{
	background: $gray-lighter;
	padding-top: 4em;
	padding-bottom: 4em;
}


.test-block{
	position: relative;
	float: right;
	width: 500px;
	height: 300px;
	background: #ccc;
}


.story{
	position: relative;
	h1,h2,h3,h4,h5,h6,p,
	ul,dl,ol,span, .zero-clipboard,
	.card, ins{
		padding-left: 1.5rem;
		padding-right: 1.5rem;
		@include media-breakpoint-up(sm) {
			max-width: $max-post-width; 
			margin-left: 0;
			margin-right: auto;
		}
	}
	blockquote{
		position: relative;
		max-width: $max-element-width;
		text-align: center;
		margin-left: auto;
		margin-right: auto;
		padding-top: 2em;
		h1,h2,h3,h4,h5,h6
		ul, dl, ol{
			max-width: $max-element-width; //avoid these overrides
		}
		h2{
			color: $brand-primary;
		}

		&:before{
			content: '“';
			display: block;
			position: absolute;
			top: 0;
			left: 50%;
			transform: translate(-50%, 0);
			margin-top: -0.5em;
			font-size: 4em;
			font-family: garamond, georgia, serif;
			color: lighten($brand-primary, 20%);
		}
	}

	.breadcrumb{
		max-width: none;
	}


	p{
		> strong{
        	color: $brand-primary;
    	}
	}
	p > a{
		text-decoration: underline;
	}


	.the-content{
		position: relative;
		@include media-breakpoint-up(sm) {
			margin-left: auto;
			margin-right: 0;
		}
		@include media-breakpoint-up(xl) {
			padding-left: 0;
			padding-right: 0;
			background: #fff;
			border-radius: 3px;
		}
	}

	.post-image-container,
	.embed-responsive,
	iframe{
		max-width: $max-element-width !important;
		margin: 3em auto !important;
		@include media-breakpoint-down(sm){
			margin-left: -15px !important;
			margin-right: -15px !important;
		}
		img{
			@include img-fluid;
		}
	}
}


.float-holder{
	max-width: $max-element-width;
	margin-left: auto;
	margin-right: auto;
}

.float-box{
	max-width: 500px;
}

.float-left{
	float: left;
}

.full-width{
	h1,h2,h3,h4,h5,h6,p,
	ul,dl,ol,span, .zero-clipboard,
	.card{
		@include media-breakpoint-up(sm) {
			max-width: none; 
			margin-left: 0;
			margin-right: 0;
		}
	}
}

.post-width{
	max-width: $max-post-width;
}

.post-content{
	background: transparent;
}


//
// Story styles
//

.story-centered{
	h1,h2,h3,h4,h5,h6,p,
	ul,dl,ol,span, .zero-clipboard,
	.card, ins{
		@include media-breakpoint-up(sm) {
			margin-left: auto;
			margin-right: auto;
		}
	}
}



//
// SPECIAL IMAGERY STYLES
//

.image-quote{
	position: absolute;
	top: 0;
	left: 0;
	z-index: 2;
	width: 100%;

	blockquote:before {
		content: '“';
		display: block;
		position: absolute;
		top: 0;
		left: 0.5em;
		transform: translate(-50%, 0);
		margin-top: -0.5em;
		font-size: 10em;
		font-family: $font-family-serif;
		color: $brand-primary;
	}
	blockquote{
		@include media-breakpoint-up(lg){
			max-width: 55%;
			margin-left: 0;
		}
	}
}

.image-stats{
	position: absolute;
	right: 0;
	bottom: 0;
	width: 60%;
	h1{
		white-space: nowrap;
	}
	p{
		font-family: $font-family-sans-serif;
		font-size: 1rem;
		line-height: 1;
		font-weight: bold;
	}
}


//
// DEFAULT STYLING FOR ELEMENTS
//

//titles & stuff

.editor-content{

	text-rendering: optimizeLegibility;
	p, h1, h2, h3, h4, h5, h6{
		text-rendering: geometricPrecision;
	}

	p{
		margin-top: 1.5em;
		-moz-font-feature-settings: "kern";
  		-moz-font-feature-settings: "kern=1";
  		-ms-font-feature-settings: "kern";
  		-o-font-feature-settings: "kern";
  		-webkit-font-feature-settings: "kern";
  		font-feature-settings: "kern";
	}

	p + h3{
		margin-top: 1.35em;
	}

	p + h5{
		margin-top: 2em;
	}

	h5 + p{
		margin-top: 0;
	}

	hr + h3{
		margin-top: 1.5em;
	}

	p + h4{
		margin-top: 1.5em;
	}

	h4 + p{
		margin-top: 0;
	}

	h4{
		font-weight: 400;
	}

	h3 + h5{
		margin-top: -0.5em;
	}

	h5 + h4{
		margin-top: -0.3em;
	}
	h5 + h6{
		margin-top: -0.5em;
		font-size: 0.8em;
	}

	blockquote{
		margin-top: 3em;
		margin-bottom: 3em;
		p{
			color: $gray;
		}
	}
}


// IMAGES
// dealing with images
//

.post-image-container{
	max-width: $max-element-width;
	text-align: center;
	em{
		display: block;
		margin-top: $spacer;
		max-width: $max-post-width;
		margin-left: auto;
		margin-right: auto;
		font-size: 0.85em;
		color: $gray;
		font-style: italic;
	}
}

.element-width{
	max-width: $max-element-width;
	text-align: center;
	margin-left: auto;
	margin-right: auto;	
}

$max-width: (
  30,
  40,
  50,
  60
) !default;
@include _assert-ascending($max-width, "$max-width");

// Max width sizing based on vhs
@mixin make-max-width($max-widths: $max-width) {
  @each $max-width in $max-widths {
      max-width: $max-width vw;
  }
}

.vw{
	@include make-max-width();
}

//
// Related posts styling
//
.related-posts{
	width: 100%;
	max-width: calc(#{$max-element-width} + 10rem;
	margin-left: auto;
	margin-right: auto;
	.card{
		margin: 0;
	}
	.article-link{
		flex-shrink: 1;

		// on small screens
		@include media-breakpoint-down(lg) {
			@include make-col(12);
			padding: 0;
			border-bottom: 1px solid $gray-lighter;
			.article-image-holder{
				@include make-col(3);
			}
			.article-content{
				@include make-col(9);
			}
			.article-footer,
			.article-date,
			.article-tags,
			.article-cat-link{
				display: none;
			}

			&:first-child{
				border-top: 1px solid $gray-lighter;
			}
		}
	}

	.article-cat-link{
		font-size: 0.8em;
		right: 1em;
		padding: 0.25em 1em;
		box-shadow: 0 5px 3px -3px rgba(0, 0, 0, 0.15);
	}
	.article-title{
		font-size: 1em;
		strong{
			display: none;
		}
	}

	@include media-breakpoint-up(lg) {
		
		.article-data{
			padding-left: 0;
			padding-right: 0;
		}
		.article-date{
			display: none;
		}
		.article-intro,
		.lead{
			display: none;
		}

		.article-footer{
			display: none;
		}

	}
}



.post-footer{
	h3{
		max-width: none !important;
	}
}



//
//
//



.highlight,
.bs-example{
	max-width: 50rem;
	margin-left: auto;
	margin-right: auto;
}


.footer-article {
	@include make-col(9);
	@include make-col-offset(3);
}

.article-footer-container {
    padding: 50px;
}

.article-footer-figure {
    height: 480px;
    position: relative;
}

.article-footer-figure-image {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    bottom: 0;
    left: 0;
    background-size: cover;
    background-position: center;

}

.text-center{
	text-align: center;
}



///
/// TEMP STYLES BUILD INTO COMPONENTS
///

// A half width featured image with short text inside card


// the image part
.scene-left{
	width: 45%;
	position: absolute;
	left: 0;
	top: 0;
	height: 100%;
}

