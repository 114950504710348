
.post-counter{
	position: absolute;
	right: 0; 
	top: 0;
	background: $brand-primary;
	color: #fff;
	padding: 0.5rem 0.5rem;
	@include media-breakpoint-up(lg){
		padding: 1.2rem 0.8rem;
	}
	@include media-breakpoint-down(lg){
		font-size: 0.8em;
	}
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 10;
	p{
		margin-bottom: 0 !important;
		font-weight: 300;
		strong{
			font-weight: 600;
		}
	}

	@include hover-focus{
		background: darken($brand-primary, 5%);
	}
}

.article-post-counter{
	position: fixed;
	top: 0;
	right: 0;
	z-index: 9000;
	will-change: transform;
	transition: transform 200ms linear;
	p{
		white-space: nowrap;
	}
}