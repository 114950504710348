

//
// HOVER EFFECTS
//

.hover-background{
	transition: 0.35s cubic-bezier(.48,-0.1,.33,1.09) all;
	.inhoud-link-content{
		transition: 0.35s cubic-bezier(.48,-0.1,.33,1.09) all;
	}
	@include media-breakpoint-up(xl){
		@include hover-focus{
			background: fade-out($primary, 0.94);
			transition: 0.35s cubic-bezier(.48,-0.1,.33,1.09) all;
			.inhoud-link-content{
				transform: translateX(1rem);
				transition: 0.35s cubic-bezier(.48,-0.1,.33,1.09) all;
			}
		}
	}
}


.hover-slow-scale{
	@include media-breakpoint-up(lg){
		transform: translate3d(0, 0, 0);

			.background-image{
				will-change: transform, opacity;
				transition: 0.5s all;
				opacity: 0.95;
			}
		@include hover-focus{
			.background-image{
				transform: scale(1.065);
				opacity: 1;
				transition: 5s transform, 0.3s opacity;
			}
		}
	}
}


//
// IMAGE SCALES UP AND CONTAINER SCALES DOWN ON HOVER
//

.hover-scale-down{
		&:before{
			background: url('/img/beeldmerk.svg') no-repeat;
			background-size: cover;
			position: absolute;
			right: 0;
			bottom: 0;
			width: 80%;
			height: 85%;
			content: '';
			z-index: 0;
			opacity: 0;
			transform: translateY(20px);
			transition: 0.35s cubic-bezier(.48,-0.1,.33,1.09) all;
		}
	@include media-breakpoint-up(lg){
		backface-visibility: hidden;
		transform: translate3d(0, 0, 0);
		.animation-holder{
			will-change: transform;
			transition: 0.3s cubic-bezier(.48,-0.1,.33,1.09) all;
			transform: translate3d(0, 0, 0);
			&:after{
				background: url('/img/beeldmerk-full.svg') no-repeat;
				background-size: cover;
				background-position: bottom left;
				position: absolute;
				right: 0;
				bottom: -6%;
				width: 70%;
				height: 60%;
				content: '';
				z-index: 0;
				opacity: 0;
				transform: translateY(10%);
				transition: 0.35s cubic-bezier(.48,-0.1,.33,1.09) all;
				
			}
		}
		.background-image{
			will-change: opacity;
			transition: 0.3s cubic-bezier(.48,-0.1,.33,1.09) all;
			opacity: 0.95;
		}
		.article-link a{
			//position: relative;
			padding-bottom: 1em;
			&:before{
				position: absolute;
				top: 100%;
				left: 0;
				width: 3em;
				height: 4px;
				background: $brand-primary;
				content: '';
				opacity: 0;
				-webkit-transition: opacity 0.3s, -webkit-transform 0.3s;
				transition: opacity 0.3s, transform 0.3s;
				transform: translateY(10px);
				z-index: 10;
			}
		}
		@include hover-focus{
			transition: 0.35s cubic-bezier(.48,-0.1,.33,1.09) all;
			.card-title{
				color: $brand-primary;
			}
			.article-link a{
				&:before{
					opacity: 1;
					transform: translateY(0px);
				}
			}
			.animation-holder{
				transform: scale(0.965) !important;
				transition: 0.35s cubic-bezier(.48,-0.1,.33,1) transform, 0.35s cubic-bezier(.48,-0.1,.33,1) opacity !important;
				&:after{
					opacity: 1;
					transform: translateY(0);
					transition: 0.35s cubic-bezier(.48,-0.1,.33,1.09) all;
				}
			}
			.background-image{
				transform: scale(1.1);
				opacity: 1;
				transition: 0.35s cubic-bezier(.48,-0.1,.33,1.09) transform, 0.35s cubic-bezier(.48,-0.1,.33,1.09) opacity !important;
			}
			.image-overlay{
				opacity: 0.55;
				transition: 0.35s cubic-bezier(.48,-0.1,.33,1.09) transform, 0.35s cubic-bezier(.48,-0.1,.33,1.09) opacity;
			}
		}
	}
}