

///
/// FULL PAGE NAV COMPONENT
/// The pages main navigation in one big page overlay

.full-page-menu-holder{
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	overflow-y: auto;
	-webkit-overflow-scrolling: touch;
	display: flex;
	align-items: flex-start;
	z-index: -1;
	background: fade-out(#fff, 0.2);
	background: linear-gradient(to right,  fade-out(rgba(255,255,255,1),0.05) 0%, fade-out(rgba(241,241,241,1),0.1) 43%, fade-out(rgba(225,225,225,1),0.05) 77%, fade-out(rgba(246,246,246,1),0.05) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	transform: translate(0,-5%);
	opacity: 0;
	transition: 0.45s all;
}

.full-page-nav-active{
	.main-navbar{
		position: fixed;
		box-shadow: 0 0 45px rgba(255,255,255,0.4);
		background: rgba(255,255,255,0.25);
	}
	.full-page-menu-holder{
		z-index: 9000;
		transform: translate(0,0);
		opacity: 1;
		transition: 0.45s all;
	}
	.full-page-menu-closer{
		transform: translateX(0);
		transition: 0.45s all;
	}
}

.full-page-nav-inverted{
	background: fade-out($brand-primary, 0.9);
}

.full-page-menu-list{
	position: relative;
	font-family: $font-family-sans-serif;
	font-size: 2em;
}

.full-page-menu-closer{
	position: fixed;
	right: 0;
	top: 0;
	background: $primary;
	font-size: 1.4em;
	padding: 0.5rem 0.7rem;
	text-align: center;
	z-index: 9100;
	transform: translateX(100%);
	@include media-breakpoint-up(lg){
		padding: 1.2rem 1rem;
	}
	@include media-breakpoint-down(lg){
		font-size: 0.8em;
	}
	a{
		color: #fff;
	}
}

.content-card-image-holder{
	max-height: 70vh;
}


//
// FULL PAGE MENU SIDE
//

.full-page-menu-side{
	min-width: 300px; 
	margin-right: $spacer * 1.5;

	.nav-item{
		border-right: 1px solid #fff;
		.active{
			border-right: 2px solid $brand-primary;
		}
	}
	.nav-link{
		display: block;
	}
}



//
//  FULL PAGE MAIN
// The main content holder styling for full-page-menus

.full-page-menu-main{
	width: 100%;
}



//
// LARGE NAVIGATION FULL PAGE
// A large, pagefilling size nav component with centered elements

.full-page-menu-large{
	text-align: center;
	display: flex;
	justify-content: center;
	padding-top: 6rem; //navbar room

	.nav-link{
		margin: $spacer;
		transition: 0.2s transform ease;
		&:hover{
			transform: scale(1.02);
			backface-visibility: hidden;
			transition: 0.2s transform ease;
		}
		&:after{
    		content: "*";
    		display: block;
    		padding-top: 0.33em;
    		color: $headings-color;
		}
	}
}


//	
// PREVIEW MENU FULL PAGE
// A full width page menu with instant item previews component

.full-page-menu-preview{

}


.content-menu-card{
	transform: translate3d(0, 0, 0);
	.image-overlay{
		background-image: linear-gradient(to top , fade-out($brand-primary, 0.48), 35% , fade-out($brand-primary, 0.65));
	}
	&:nth-child(even){
		.image-overlay{
			background-image: linear-gradient(to top , fade-out($brand-info, 0.48), 35% , fade-out($brand-info, 0.65));
		}
	}
}


//
//
.post-details{
	transform: translate3d(0,0,0);
	will-change: transform;
}

.post-details-excerpt-holder{
	will-change: transform;
	transform: translate(0, -100%);
	overflow: hidden;
	transition: transform 1s cubic-bezier(.19,1,.22,1);
	margin-bottom: $spacer;
}

.post-details-excerpt{
	will-change: transform;
	transform: translateY(100%);
	transition: 1s transform cubic-bezier(.19,1,.22,1);
	margin: 0;
	h6{
		margin-bottom: 0;
		transform: translateY(100%);
		transition: .6s transform cubic-bezier(.19,1,.22,1);
	}
}

.post-details-link{
	will-change: transform;
	transition: .6s transform cubic-bezier(.19,1,.22,1);
}


.content-menu-card{
	.post-details-title{
		transform: translate(0, 2rem);
		transition: transform 1s cubic-bezier(.19,1,.22,1);
	}
	@include media-breakpoint-up(lg){
		&:hover{
			.post-details-title{
				transform: translate(0, 0) !important;
				transition: 1s transform cubic-bezier(.19,1,.22,1);
			}
			.post-details-excerpt-holder,
			.post-details-excerpt,
			.post-details-excerpt h6,
			.post-details-link{
				transform: translate(0,0) !important;
			}

		}
	}
}






