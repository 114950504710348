


//
// Navigation elements hover effects
//


// Move the hovered item to the right slightly
.hover-slide{
	transition: 0.2s transform;
	@include hover-focus{
		transform: translate(0.2em,0);
		transition: 0.3s transform ease-out;
	}
}

.scroll-indicator{
	position: absolute;
	bottom: 0;
	left: 50%;
	transform: translate(-50%, 0);
	color: #fff !important;
	text-transform: uppercase;
	font-family: $font-family-sans-serif;
	font-weight: 400;
	letter-spacing: 0.08em;
	z-index: 100;
}



// Other indicators


.heading-with-symbol{
	display: flex;
	align-items: center;
	h4{
		margin-right: 1em !important;
		margin-bottom: 0;
	}
}

.explainer-symbol{
	display: flex;
	flex: 1;
	align-items: center;
	.circle{
		width: 12px;
		height: 12px;
		border-radius: 100px;
		border: 1px solid fade-out($gray, 0.1);
	}
	.line{
		flex: 1 1 auto;
		height: 1px;
		border: 1px solid fade-out($gray, 0.2);
	}
}



.screen-wrapper{
	position: relative;
	width: 100%;

	.content{
		width: 100%;
		margin-left: auto;
		margin-right: auto;
	}
}

.half-width-image,
.half-width-image img,
.screen-wrapper{
	transition: 0.4s ease-in-out 0.1s;
}

.screen{
	position: relative;
	transition: 0.2s all;
}

.screens.active{

	.half-width-image{
		width: 100%;
		padding-top: 5em;
		right: auto;
		left: 0;
		max-width: 90vw;
		transition: 0.4s ease-in-out 0.2s;
	}

	.screen-content{
		width: 90%;
		padding: 8rem 5rem;
		margin-left: auto;
		margin-right: auto;
	}

	.screen-text{
		transition: 0.2s all;
		width: 100%;
	}

	.screen-text-hide{
		opacity: 0;
		transition: 0.2s all;
	}

	img{
		margin-left: auto;
		margin-right: auto;
	}
}

.screen.active{
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	width: 100vw;
	height: 100vh;
	z-index: 9999;
	overflow-y: auto;
	background: #fff;
	-webkit-overflow-scrolling: touch;
	transition: 0.2s all;
}




.invisible{
	opacity: 0;
}



.nav-roundslide a{
	position: absolute;
	bottom: 3rem;
	display: flex;
	align-items: center;
	outline: none;
	text-align: left;
	z-index: 1000;
	-webkit-transform: translateY(-50%);
	transform: translateY(-50%);
	margin: 0 20px;
	padding: 0.25em;
	width: 3em;
	height: 3em;
	border-radius: 3em;
	background: rgba(0,0,0,0.65);
	color: #fff;
	-webkit-transition: width 0.3s,
	background-color 0.3s;
	transition: width 0.3s,
	background-color 0.3s;
	overflow: hidden;


	.icon-wrap{
		position: absolute;
		width: 1.5em;
		text-align: center;
	}

	p{
		position: absolute;
		top: 0;
		margin: 0;
		white-space: nowrap;
		font-size: 0.85em;
		 line-height: 3.5;
		opacity: 0;
		transition: opacity 0.3s, transform 0.3s;
	}

	&:hover{
		width: 220px;
		background: rgba(255,255,255,0.85);
		color: #111;

		p{
			opacity: 1;
			transition-delay: 0.1s;
			transform: translateX(0) !important;
		}
	}

	&.slider-prev{
		left: 3rem;
		p{
			left: 3.5em;
			transform: translateX(100%);
		}
		.icon-wrap{
			left: 15px;
		}
	}

	&.slider-next{
		right: 3rem;

		p{
			right: 3.5em;
			transform: translateX(-100%);
		}
		.icon-wrap{
			right: 15px;
		}
	}
}


