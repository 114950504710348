
$footer-text-color: rgba(255,255,255,0.85);

.page-footer{
	background: $brand-primary;
	color: #fff;
	h2{
		color: #fff;
	}
}

.slogan-footer{
	align-self: center;
	flex: 1 1 auto;
	text-align: center;
	h2{
		font-family: $font-family-serif;
	}
}

.main-footer{
	@include media-breakpoint-up(lg){
		position: fixed;
		bottom: 0;
		left: 0;
		right: 0;
	}
	max-width: 100vw;
	margin-left: auto;
	margin-right: auto;
	display: flex;
	justify-content: space-between;
	font-family: $font-family-sans-serif;
	font-size: 0.8em;
	letter-spacing: -0.01em;
	p{

		line-height: 1.5;
		font-weight: 400;
	}

}

.footer-data,
.footer-social{
	display: flex;
	flex: 1;
	justify-content: space-between;
	flex-flow: row;
	padding: 1em;
}

.address-data{
	display: flex;
}

.footer-social{
	display: none; // no active links yet
	list-style: none;
	> li > a{
		color: $footer-text-color;
		&:after{
			content: "/";
    		margin: 0 .35em 0 .6em;
		}
		&:hover{
			text-decoration: none;
		}
	}
	> li:last-child > a:after{
    	content: "";
    }
}

.footer-data{
	> * {
		padding: 0 0.5em;
	}
}

.page-footer{
	@include media-breakpoint-up(lg){
		position: fixed;
		z-index: 1;
		bottom: 0;
		left: 0;
		width: 100vw;
		height: 30em;
	}
	padding: 0;
	display: flex;
	flex-flow: row wrap;
	align-items: stretch;
	justify-content: center;
	border: 0rem solid $brand-info;
	padding: 3rem 1rem 3rem;
	border-top: none;
	transition: 0.3s border ease, 0.3s padding ease;
	backface-visibility: hidden;
	max-width: 100vw;
	&:hover{
		/*border: 1rem solid #fff;
		padding: 0 0rem 0rem;
		border-top: none;
		transition: 0.3s border ease, 0.3s padding ease;*/
	}
}

.footer-spacer{
	@include media-breakpoint-up(lg){
		position: relative;
		height: 30em;
		width: 100%;
	}
}



//
// IF the page has a sidebar the footer is narrower

.has-sidebar{
	@include media-breakpoint-up(lg){
		.main-footer,
		.page-footer{
			width: calc(100vw - 6rem);
			left: 6rem;
		}
	}
}

//

