
.form-group{
	position: relative;
}

.form-control {
	border-width: 0 0 1px 0;
	transition: 0.2s ease-in border-color;
	padding-left: 0.1rem;
	padding-right: 0;
  border-radius: 0;
  margin-bottom: 2rem;
  background: transparent;
	&:focus{
		border-bottom-width: 2px;
            background: transparent;
		&:valid{
			border-color: $brand-info;
		}
		&:invalid{
			border-color: $brand-danger;
		}
	}
}

/* LABEL ======================================= */
label          {
  color:$brand-primary; 
  font-weight:normal;
  position:absolute;
  pointer-events:none;
  left:5px;
  top:10px;
  transition:0.2s ease all; 
  -moz-transition:0.2s ease all; 
  -webkit-transition:0.2s ease all;
}

.form-control+ .form-control-label {
    -webkit-transition: 0.2s ease all;
    transition: 0.2s ease all;
    cursor: pointer;
    transform: translateY(0);
    font-size: 1em;
    left: 0.1rem;
    top: 0.25em;
    position: absolute;
    color: $brand-info;
}

.form-control::-webkit-input-placeholder {
  color: transparent;
}

.form-control:focus::-webkit-input-placeholder,
.form-control[value]::-webkit-input-placeholder,
.form-control:valid::-webkit-input-placeholder {
  color: transparent;
}

.form-control:focus + .form-control-label,
.form-control[value] + .form-control-label,
.form-control:focus:valid + .form-control-label {
          transform: translateY(-1.75em);
          font-size: 0.75em;
          color: $brand-info;
}

.form-control:focus:invalid + .form-control-label {
	color: $brand-danger;
}

.form-control:valid + .form-control-label{
	transform: translateY(-1.75em);
            font-size: 0.75em;
}

// if form-control is select...
.c-select + .form-control-label{
          transform: translateY(-1.75em);
          font-size: 0.75em;
}		