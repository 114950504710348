

.homepage-header{
	position: relative;
	width: 100vw;
	overflow: hidden;
	min-height: 0vh;
	.jumbo{
		h1{
			font-size: 20vw;
		}
		@include media-breakpoint-up(md){
			padding: 5vw;
			max-width: 75vw;
			h1{
				font-size: 14vw;
				line-height: 0.84;
			}
		}
	}

	.bg-graphic{
		background-image: url('/img/beeldmerk.svg');
		background-repeat: no-repeat;
		background-size: cover;
		background-position: left bottom -10vh;
		width: 100vw;
		height: 60vh;
		@include media-breakpoint-up(lg){
			height: 80vh;
			width: 50vw;
			background-position: right -10vw bottom -5vw;
		}
		position: absolute;
		right: 0;
		bottom: 0;
		opacity: 0.4;
	}
}

