
.project{
	width: 100%;
	height: 60vh;

	.content{
		height: 100%;
	}
	p{
		margin-bottom: 1em;
	}
	.label{
	}
	h3{
		margin-top: 10px;
		margin-bottom: 0;
		line-height: 1.23em;
	}
	.content{
		overflow: hidden;
		height: 100%;
		position: relative;
		background-position: center center;
		background-size: cover !important;
		transition: 0.3s all;

		.overlay{
			opacity: 0.45;
		}

	}
	.bg-primary{
		padding: 0.3em;
		background: $gray-dark;
	}
}