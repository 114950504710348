// Contextual backgrounds

@mixin bg-variant-light($parent, $color) {
  #{$parent} {
    background-color: $color !important;
  }
  a#{$parent} {
    @include hover-focus {
      background-color: darken($color, 10%);
    }
  }
}


// background coloring
@include bg-variant-light('.bg-white', #fff);
@include bg-variant-light('.bg-gray-light', $gray-light);
@include bg-variant-light('.bg-gray-lighter', $gray-lighter);
@include bg-variant-light('.bg-gray-lightest', $gray-lightest);
@include bg-variant-light('.bg-info-muted', (fade-out($brand-info, 0.8)));

.bg-kve-light{
  background: fade-out($brand-primary, 0.85);
}


@mixin bg-variant-fade($parent, $color) {
  #{$parent} {
    background-color: fade-out($color,0.94) !important;
  }
  a#{$parent},
  button#{$parent} {
    @include hover-focus {
      background-color: darken($color, 10%) !important;
    }
  }
}

@each $color, $value in $theme-colors {
  @include bg-variant-fade(".bg-fade-#{$color}", $value);
}

///
/// SHADOWS
///

@mixin hover-shadow{
	box-shadow: 0 60px 135px rgba(0,0,0,0.15), 0 15px 75px rgba(0,0,0,0.17);
}

.hover-shadow{
	@include hover-shadow;
}

