.next-project{
	position: relative;
	display: flex;
	flex: 1;
	align-items: center;
	justify-content: center;
	overflow: hidden;
	margin: 0 1rem;
	padding-top: 5rem;
	padding-bottom: 5rem;

	&:first-child{
		border-right: 1px solid $gray-lighter;
	}

	@include media-breakpoint-up(lg){
		padding-top: 7.5rem;
		padding-bottom: 7.5rem;
	}

	background-size: cover;
	background-position: top right;

	color: #fff;
	text-align: center;

	.background-image{
		transition: 1s transform ease-in-out;
		background-position: top right;
		//opacity: 0.2;
	}

	h2, h3, h4{
		color: $brand-inverse;
	}

	h5{
		color: #fff;
	}

	.content{
		position: relative;
		padding: 1em;
	}

	.overlay{
		transition: 0.2s all;
	}

	&:hover,
	&:focus{
		text-decoration: none;
		.overlay{
			opacity: 0.8;
			transition: 0.2s all;
		}
		.background-image{
			transform: scale(1.1);
			transition: 5s transform ease-in-out;
		}
	}
}

.prev-next-posts{
	border-top: 1px solid $gray-lighter;
	border-bottom: 1px solid $gray-lighter;
}

.next-post-content{
	position: absolute;
	left: 0;
	top: 50%;
	transform: translateY(-50%);
}