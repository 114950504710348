

//
//
//

.map-holder{
	min-height: 400px;
	max-height: 1200px;
	height: 90vh;
}

#google-container{
	height: 100%;
}