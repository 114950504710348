

/* ==========================================================================
   Browse Happy prompt
   ========================================================================== */

   .browsehappy{
    display: none !important;
}

[class*="IE"]{
    .browsehappy {
        display: block;
        margin: 0.2em 0;
        background: #eee;
        color: #000;
        padding: 5em 3em !important;
        position: fixed;
        top: 10%;
        left: 0;
        width: 100%;
        z-index: 2000;
        font-size: 1.5em;
        line-height: 1.5;
        text-align: center;
        .close{
            position: absolute ;
            right: 0.5em;
            top: 0.5em;
        }
    }
}

[class*="IE"].warning-dismissed .browsehappy{
    display: none !important;
}

.NO_IE .browsehappy{
  display: none !important;
}

html.sr .NO_IE .load-hidden {
  visibility: hidden;
}


.main{
  max-width: 100vw;
}

//containers


html.sr .load-hidden {
  visibility: hidden;
}


.container-fluid{
  @include media-breakpoint-down(sm){
    padding-left: 2rem;
    padding-right: 2rem;
    .row{
      margin-left: -2rem;
      margin-right: -2rem;
    }
  }
}


.container-narrow{
    padding-left: 20px;
    padding-right: 20px;   

    @include media-breakpoint-up(sm){
        margin-left: 8vw;
        margin-right: 8vw;
    }
    @include media-breakpoint-up(xl){
        margin-left: 12vw;
        margin-right: 12vw;
    }
    @include media-breakpoint-up(s-xl){
        margin-left: 18vw;
        margin-right: 18vw;
    }
    &.to-edge.to-edge-right{
      @include media-breakpoint-up(sm){
        margin-right: 0; // container-narrow 12rem + 2 rem padding
      }
    }

    &.to-edge.to-edge-left{
      @include media-breakpoint-up(sm){
        margin-left: 0; // container-narrow 12rem + 2 rem padding
      }
    }

    .to-edge.to-edge-right{
      @include media-breakpoint-up(xl){
        margin-right: -12vw; // container-narrow 12rem + 2 rem padding
      }
    }

    .to-edge.to-edge-left{
      @include media-breakpoint-up(xl){
        margin-left: -12vw; // container-narrow 12rem + 2 rem padding
      }
    }
}

//
// CONTAINER WIDE
// A full width container with generous padding on the outside

.container-wide{
    @include media-breakpoint-down(sm){
        padding-left: 20px;
        padding-right: 20px;   
    }
    @include media-breakpoint-up(sm){
        margin-left: 4vw;
        margin-right: 4vw;
    }

    @include media-breakpoint-up(xl){
        margin-left: 8vw;
        margin-right: 8vw;
    }
     @include media-breakpoint-up(s-xl){
        margin-left: 12vw;
        margin-right: 12vw;
    }

    &.to-edge.to-edge-right{
      @include media-breakpoint-up(sm){
        margin-right: 0; // container-narrow 12rem + 2 rem padding
      }
    }

    &.to-edge.to-edge-left{
      @include media-breakpoint-up(sm){
        margin-left: 0; // container-narrow 12rem + 2 rem padding
      }
    }

    .to-edge.to-edge-right{
      @include media-breakpoint-up(xl){
        margin-right: -8vw; // container-narrow 12rem + 2 rem padding
      }
      @include media-breakpoint-up(s-xl){
        margin-right: -12vw; // container-narrow 12rem + 2 rem padding
      }
    }

    .to-edge.to-edge-left{
    @include media-breakpoint-up(xl){
        margin-left: -8vw; // container-narrow 12rem + 2 rem padding
      }
      @include media-breakpoint-up(s-xl){
        margin-left: -12vw; // container-narrow 12rem + 2 rem padding
      }
    }
}


//
// CONTAINER ULTRA WIDE
// A full width container with generous padding on the outside

.container-ultra-wide{
    @include media-breakpoint-down(sm){
        padding-left: 10px;
        padding-right: 10px;   
    }
    @include media-breakpoint-up(sm){
        margin-left: 4vw;
        margin-right: 4vw;
    }

    &.to-edge.to-edge-right{
      @include media-breakpoint-up(sm){
        margin-right: -4vw; // container-narrow 12rem + 2 rem padding
      }
    }

    &.to-edge.to-edge-left{
      @include media-breakpoint-up(sm){
        margin-left: -4vw; // container-narrow 12rem + 2 rem padding
      }
    }
}


//
// EXTRA OFFSET UTIL
// 

.offset-xl-0-5{
  @include media-breakpoint-up(xl){
    margin-left: calc(0.5/12 *100%) !important;
  }
}


// ASPECT RATIO'S
//

$aspects: () !default;
$aspects: (
    '24-9': 9/24,
    '21-9': 9/21,
    '16-9': 9/16,
    '3-2': 2/3,
    '2-1': 1/2,
    '1-1': 1/1,
    '1-2': 2/1,
    '1-3': 3/1,
    '1-15': 1.5/1
);

@each $breakpoint in map-keys($grid-breakpoints) {
    @include media-breakpoint-up($breakpoint) {
        $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

        @each $name, $aspect in $aspects {

            .aspect-ratio#{$infix}-#{$name} { 
                position: relative;
                &:before{
                    display: block;
                    content: " ";
                    width: 100%;
                    padding-top: ($aspect) * 100%;
                }
            }

        }
    }
}

///
// FLEX HELPERS NOT IN BOOTSTRAP
///

.flex-grow{
    flex-grow: 1;
    max-width: 100%;
}



//
// POSTITIONING ATTRIBUTES
//
.pos-abs{
    position: absolute !important;
}

.pos-lg-abs{
    @include media-breakpoint-up(lg){
        position: absolute !important;
        height: 100%;
        top: 0;

        &.container-fluid{
            width: 100%;
        }
    }
}

.pos-rel{
    position: relative !important;
}

.pos-lg-rel{
    @include media-breakpoint-up(lg){
        position: relative !important;

    }
}



.z-0 { z-index: 0; }
.z-1 { z-index: 1; }
.z-2 { z-index: 2; }
.z-3 { z-index: 3; }
.z-4 { z-index: 4; }
.z-5 { z-index: 5; }
.z-999 { z-index: 999; }
.z-9999 { z-index: 9999; }
.z-max { z-index: 2147483647; }
.z-inherit { z-index: inherit; }
.z-initial { z-index: initial; }
.z-unset { z-index: unset; }