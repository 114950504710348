


///
/// RANDOM PAGE LAYOUT BLOCKS
/// small classes that dont need a separate file



// SLOGAN BANNER
// write a big slogan in a separate section

.slogan-banner{
  min-height: 40vh;
  display: flex;
  align-items: center;
  max-width: 100vw;
  overflow-x: hidden;
}



.home-intro__image {
    width: 1487px;
    height: 846px;
    background-size: 1487px 846px;
}

.about-intro-content {
    max-width: 35rem;
    position: relative;
    z-index: 2;
}

.about-intro-container {
    width: 1320px;
    position: static;
}

.home-intro__image {
  /* @x: 1324px;
		@y: 787px;
		@offset: 80px; */
  position: static;
  height: 0;
  padding-bottom: 50%;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  background-image: url("https://reuters.tv/about/img/home/web-app-range2.jpg");
  background-size: contain;
  background-repeat: no-repeat;
}
@media (min-width: 768px) {
  .home-intro__image {
    position: absolute;
    top: -80px;
    left: 44%;
    width: 175.76832151%;
    height: 100%;
    background-size: 817.85px 465.3px;
  }
}
@media (min-width: 1200px) {
  .home-intro__image {
    top: -50px;
    background-size: 65vw;
    background-position: top left;
  }

}

.home-intro__inner {
    height: 970px;
}



