


//
// FULLSCREEN MENU
//

body{
	overflow-x: hidden;
}

.mobile-menu-holder{
	position: fixed;
	top: 0;
	right: 0;
	width: calc( 100vw - 4rem );
	@include media-breakpoint-up(sm){
		width: 50vw;
	}
	height: 100vh;
	background: #fff;
	z-index: 100;
	display: flex;
	padding: $spacer;
	@include media-breakpoint-up(sm){
		padding: $spacer * 2;
	}
	overflow-y: auto;
	-webkit-overflow-scrolling: touch;

	// Move offscreen default
	transform: translate(100%, 0);
	transition: 0.25s ease-in transform;
}

// animation defaults for moving components
.page-holder,
.side-always-visible{
	transition: 0.5s ease-in-out transform;
}


// The menu's active state
.mobile-nav-active{
	.mobile-menu-holder{
		transform: translate( 0 ,0 );
		transition: 0.25s ease-in-out transform;
	}
}


.mobile-menu-side{
	flex: 1;
}

.mobile-menu-nav{
	.nav-item{
	}
	.nav-link{
		font-size: 1.5em;
	}
}

.mobile-menu-main{
	flex: 5;
}


//
// THE LATEST WORK MENU
//


.work-menu-item-image{
	background-size: cover;
	background-repeat: no-repeat;
}


