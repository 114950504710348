/*
 * CSS Animations
 * Don't forget to add vendor prefixes!
 */


.transition-fade {
  transition: 0.2s opacity 0.5s transform;
}

.nav__link {
  position: fixed;
  top: 2rem;
  display: block;
  width: 4vw;
  min-width: 1.6rem;
  transition: fill .35s ease-in-out;
  fill: pink;
  margin-top: 5em;
  
  &:hover {
    fill: blue;
  }
  
  svg {
    width: 100%;
  }
}