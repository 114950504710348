

///
/// CARD STYLES 
/// Different card styles are defined here


// LINK BOX 
// for linking to articles and other stuff. Has a background image with overlayed text

.link-box{
	position: relative;
	display: flex;
	align-items: flex-end;
	overflow: hidden;

	.background-image{
		transform: scale(1.02);
		transition: 0.25s ease-in-out;
	}

	.content{
		position: relative;
		padding: 5rem;
		min-width: 100%;

	}

	&:hover{
		.background-image{
			transform: scale(1);
			transition: 0.25s ease-in-out;
		}
		text-decoration: none;
	}
}

.link-box-title{
	font-weight: 600;
	margin-bottom: 0.66em;
}

.link-box-copy{
	max-width: 35em;
}

.category-label{
	font-family: $font-family-sans-serif;
	letter-spacing: 0.02em;
	text-transform: uppercase;
	margin-bottom: 1em;
	font-size: 0.8em;
	font-weight: 300;
	color: $gray;
}



.card-list-news{
	padding-top: $spacer;
	padding-bottom: $spacer;
	border-bottom: 2px solid $gray-lighter;
}


.card-list-news-large{
	display: flex;
	align-items: stretch;
}


// background image transform
.card{
	.background-image{
		transition: 0.25s transform ease;
	}


	/*
	@include hover-focus{
		.background-image{
			transform: scale(1.01);
			transition: 0.25s transform ease;
		}
	}*/
}

.card-image-holder{
	padding-left: 0;
	padding-right: 0;
}

.card-content{
	@include media-breakpoint-up(xl){
		padding: $spacer * 2;
	}
		display: flex;
		flex-flow: row wrap;
		align-items: center;
		position: relative;
		left: 0;
		
}

.pos-abs.card-content{
	z-index: 10000;
}

.card-content-link{
	border-top: 1px solid $gray-lighter;
	display: block;
	width: 100%;
	padding-top: $spacer;
}

.card-category-vertical{
	display: flex;
	align-self: flex-end;
	color: $gray-light;
	transform: rotate(-90deg) translate(50%, 0);
	padding: $spacer 0;

	h3, h4, h5, h6{
		white-space: nowrap;
		margin-bottom: 0;
	}

	@include media-breakpoint-down(lg){
		display: none;
	}
}



//
// FULL BACKGROUND
// big image card with a full size background and floating block

.card-full-background{
	.card-content{
		@include media-breakpoint-up(lg){
			position: absolute;
			bottom: -2rem;
			left: -2rem;
			background: #fff;
			max-width: 30rem;
		}
		@include media-breakpoint-up(xl){
			bottom: $spacer * 2;
			left: $spacer * 2;
		}
	}
}

.card-project{
	display: flex;
	flex-flow: row wrap;
	height: auto;
	min-height: 60vh;
	@include media-breakpoint-up(lg){
		min-height: 100vh;
	}
	.card-content{
		align-self: flex-end;
		display: block;
	}
}

.card-content-inverse{
	//coloring
	h1,h2,h3,h4,h5,h6,p{
		color: #fff;
	}
}



//
//
.project-details{
	@include media-breakpoint-up(lg){
		padding-bottom: 12vh;
		padding-left: 6vh;
		padding-right: 6vh;
	}
	transform: translate3d(0,0,0);
}

.project-details-excerpt-holder{
	transform: translate(0, -100%);
	overflow: hidden;
	transition: transform 1s cubic-bezier(.19,1,.22,1);
	margin-bottom: $spacer;
}

.project-details-excerpt{
	transform: translateY(100%);
	transition: 1s transform cubic-bezier(.19,1,.22,1);
	margin: 0;
	p{
		margin-bottom: 0;
		transform: translateY(100%);
		transition: .6s transform cubic-bezier(.19,1,.22,1);
	}
}

.project-details-link{
	transition: .7s transform cubic-bezier(.19,1,.22,1);
}



.card-project{
	.project-title{
		transform: translate(0, 2rem);
		transition: transform 1s cubic-bezier(.19,1,.22,1);
	}
	@include media-breakpoint-up(lg){
		&:hover{
			.project-title{
				transform: translate(0, 0) !important;
				transition: 1s transform cubic-bezier(.19,1,.22,1);
			}
			.project-details-excerpt-holder,
			.project-details-excerpt,
			.project-details-excerpt p,
			.project-details-link{
				transform: translate(0,0) !important;
			}

		}
	}
}




//
// Homepage design details
//

//mobile

.small-blocks{
	@include media-breakpoint-down(sm){
		.col{
			min-width: 100%;
			display: flex;
			flex-wrap: wrap;
			align-items: center;
		}
		.background-image-holder{
			flex-basis: 33%;
			max-width: 33%;
			height: 100%;
			margin-right: 1rem;
			display: inline-block;
			&:after{
				padding-top: 100%;
			}
		}
		.small-block-content{
			max-width: calc(66% - 1rem);
			align-items: center;
		}
		.post-counter{
			font-size: 0.65em;
			padding: 0.6rem 0.4rem;
			right: auto;
			left: 0;
		}
	}
}



