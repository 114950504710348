
.pull-right{
	float: right;
}

.navbar-transparent{
	opacity: 0;
}

.main-navbar{
	font-family: $font-family-sans-serif;
	display: flex;
	justify-content: space-between;
	z-index: $zindex-navbar;
	width: 100%;
	position: fixed;
	top: 0;
	left: 0;
	background: #fff;
	.container-wide{
		width: 100%;
	}
	.container-fluid{
		display: flex;
		flex-wrap: wrap;
		text-align: center;
		justify-content: center;
		@include media-breakpoint-up(xl){
			display: flex;
			flex-wrap: nowrap;
			justify-content: space-between;
		}
	}
}


.navbar-brand-holder{
	justify-content: center;
	text-align: center;
	align-items: center;
}

.navbar-brand{
	width: 150px; // define sizes..
	max-width: 200px;
	font-weight: 600; //demo
	letter-spacing: -0.07em; //demo
	white-space: normal;
	text-align: center;
	margin-right: 0;
	@include media-breakpoint-up(lg){
		width: 180px;
		max-width: 200px;
		img{
			max-height: 110px;
		}

	}
}

//
// HOMEPAGE NAVBAR STYLING
//

.main-navbar{
	transition: 0.25s ease-in-out;
	@include media-breakpoint-up(xl){
		padding: 0rem;
		font-size: 1.1em;
		height: 6rem;
		align-items: center;
	}
	.nav-item{
		margin-right: 1.5em;
		&:last-child{
			margin-right: 0;
		}
	}

	.nav-items{
		justify-content: center;
			flex: 0 0 100%;
			margin-top: 20px;
		@include media-breakpoint-up(lg){
			justify-content: flex-end;
			flex: 1 0 0;
			margin-top: 0;
		}
	}
	.nav-link{
		position: relative;
		font-weight: 400;
		color: $headings-color;

		&:after{
			position: absolute;
			top: 100%;
			left: 0;
			width: 100%;
			height: 4px;
			background: $brand-primary;
			content: '';
			opacity: 0;
			-webkit-transition: opacity 0.3s, -webkit-transform 0.3s;
			transition: opacity 0.3s, transform 0.3s;
			transform: translateY(10px);
		}
		&:hover:after{
			    opacity: 1;
			    transform: translateY(0px);
		}
	}
}

.navbar-bg{
	padding: ($spacer / 4) $spacer;
	@include media-breakpoint-up(xl){
		height: 4rem;
	}
	background: #fff;
	box-shadow: 0 0 4px rgba(0,0,0,0.1);
	transition: 0.25s ease-in-out;

	transform: translate(0,-100%);
	&.navbar-hover{
		transform: translate(0,0);
		transition: 0.25s ease-in-out;
	}
}


.dark-header{
	.nav-link{
		color: #fff;
	}
}


//
// HEADROOM
// 

.headroom {
  will-change: transform;
  transition: transform 200ms linear;
}
.headroom--pinned {
  transform: translateY(0%);
}
.headroom--unpinned {
  transform: translateY(-100%);
}

.article-post-counter.pinned{
	transform: translateY(85px);
	will-change: transform;
  	transition: transform 200ms linear;
	@include media-breakpoint-up(lg){
		transform: translateY(96px);
	}
}