


///

.btn{
	font-family: $font-family-sans-serif;
}

.btn-secondary-outline{
	@include hover{
		color: $brand-primary;
	}
}