

.has-icon {
    padding-left: 3.5em;
    position: relative;

    .icon{
    	position: absolute;
    	left: 0;
    	font-size: 2em;
    }
}

.has-icon .fa {
    position: absolute;
    top: 0;
    left: 0;
    margin-top: 0;
}



// HUGE BACKGROUND ICONS
//

.huge-background-icon{
	color: fade-out($brand-primary, 0.9);
	position: absolute;
	margin-left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	font-family: $font-family-sans-serif;
	font-size: 10vw;
	line-height: 0.65;

	h1{
		color: fade-out($brand-info, 0.95);
		font-size: 45vw;
		font-weight: 600;
		letter-spacing: -0.02em;
	}
}

.has-icon-left{
	position: relative;
	padding-left: 2em;
	.icon-holder{
		position: absolute;
		margin-left: -2em;
	}
}

.huge-background-text{
	position: relative;
	color: fade-out($brand-primary, 0.25);
	font-family: $font-family-sans-serif;
	font-size: 10vw;
	line-height: 0.65;

	&.right{
		left: auto;
		right: 12vw;
	}
}



//
// QUOTE SIGNS
//

.quote-sign-circle{
	font-size: 6em;
	line-height: 1;
	//background: $brand-primary;
	//color: #fff;
	margin-top: -0.4em;
	margin-bottom: -1em;
	margin-left: $spacer * 1.5;
	display: inline-block;
	z-index: 20;
	position: absolute;
	display: inline-flex;
	align-items: center;
	span{
		font-size: 2em;
	}
}
