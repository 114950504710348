

//
// Move the nav according to project overview
//

.work .navbar-brand{
	@include media-breakpoint-up(lg){
		margin-left: 5rem;
	}
}

.projects-overview{
	@include media-breakpoint-up(lg){
		padding-top: 0;
		position: absolute;
		top: 0;
		left: 0;
		margin-left: 6rem; //sidebar width??
		display: flex;
		flex-flow: row wrap;
		align-items: center;
		width: calc(100vw - 6rem);
		min-height: 100vh;
	}
	width: 100vw;
	z-index: 10;
	padding: 2em 0;

	.swiper-container{
		position: relative;
		@include media-breakpoint-up(lg){
			position: absolute;
		}
	}

	.swiper-wrapper{
	position: relative;
		@include media-breakpoint-up(lg){
			max-width: calc(100vw - 12rem);
			padding-left: 5rem;
		}

	}
}

/*.project-swiper-container{
	width: auto;
	opacity: 0;
}*/

.animate{
	.project-swiper-container{
		opacity: 1;
		transition: 0.5s ease-in all 0.5s;
	}
}

.project-card{
	position: relative;
	height: 75vh;
	width: 99vw;
	@include media-breakpoint-up(lg){
		flex: 0 0 52vw;
		min-width: 40vw;
		min-height: 500px;
		max-height: 60vh;
		margin-right: 60px;
	}
	border: none;
	transition: 0.2s all ease-out;
	@include hover-shadow;9
	.overlay{
		opacity: 0.2;
	}

	&:hover{
		transform: scale(1.01);
		transition: 0.1s all ease-in;
	}
}

.project-card-image-holder{
	position: relative;
}
.project-card-image{
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	background-size: cover;
}

.project-card-title{
	position: absolute;
	bottom: $spacer;
	left: $spacer;
	right: $spacer;
	color: $brand-primary;
	@include media-breakpoint-up(lg){
		bottom: 2.5rem;
		left: 5rem;
	}
}

.work-bg-text{
	font-size: 22vw;
	line-height: 1;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

