.img-fit{
	object-fit: cover;
	object-position: 50% 50%;
	font-family: 'object-fit: cover; object-position: center;';
}

.img-contain{
	object-fit: contain;
	font-family: 'object-fit: cover;';
}

.data-image{
	img{
		display: none;
	}
}

.shadow-img{
	box-shadow: 0 0 3em rgba(0,0,0,0.25);
}

.background-image-holder{
}


// edge to edge images on mobile

.image-col{
	@include media-breakpoint-down(sm){
		padding-left: 2px;
		padding-right: 2px;
	}
}

.background-image{
	position: absolute;
	left: 0;
	top: 0;
	height: 100%;
	width: 100%;
	background-size: cover;
	background-position: center center;
	&.col-sm-4{
		width: 33.333333%;
	}
 }

 .data-image{
 	img{
 		display: none;
 	}
 }





 //
 // ATTENTION BLOCKS
 //

 .image-attention-block{
	max-width: 80vw;
	@include media-breakpoint-up(lg){
		position: absolute;
		right: 30vw;
		top: -3em;
		max-width: 70vw;
	}
	@include media-breakpoint-up(xl){
		max-width: 25vw;
		right: 8rem;
	}
}

.image-attention-block-bottom{
	top: auto;
	bottom: -3em;
}

.image-attention-block-left{
	right: auto;
	left: $spacer;
	@include media-breakpoint-up(md){
		  left: 8rem;
   }
}

.image-attention-block-left-bottom{
	right: auto;
	left: 0;
	@include media-breakpoint-up(lg){
		left: -3rem;
	}
	top: auto;
	bottom: $spacer;
}

 .image-attention-block-left-top{
	right: auto;
	left: 0;
	@include media-breakpoint-up(lg){
		left: -3rem;
	}
	bottom: auto;
	top: $spacer;
}





// IMAGE OVERLAYS
// graddient backgrounds overlayed on images for better text readability 

 .image-overlay{
 	position: absolute;
	left: 0;
	top: 0;
	height: 100%;
	width: 100%;

	&.light{
		background-image: linear-gradient(to top , fade-out(#fff, 0),55%, fade-out(#fff, 1));
		@include media-breakpoint-up(md) {
			background-image: linear-gradient(to right , fade-out(#fff, 0.25), 55% , fade-out(#fff, 0.5));
		}
	}
	&.light-vertical{
		background-image: linear-gradient(to top , fade-out(#fff, 0),55%, fade-out(#fff, 1));
		@include media-breakpoint-up(md) {
			background-image: linear-gradient(to top , fade-out(#fff, 0.55), 35% , fade-out(#fff, 1));
		}
	}
	&.light-horizontal{
		background-image: linear-gradient(to right, fade-out(#fff, 0),55%, fade-out(#fff, 1));
		@include media-breakpoint-up(md) {
			background-image: linear-gradient(to right, fade-out(#fff, 0.55), 35% , fade-out(#fff, 1));
		}
	}
	&.light-reversed{
		background-image: linear-gradient(to top , fade-out(#fff, 0),55%, fade-out(#fff, 1));
		@include media-breakpoint-up(md) {
			background-image: linear-gradient(215deg , fade-out(#fff, 0.5), 35% , fade-out(#fff, 1));
		}
	}
	&.darker-vertical{
		background-image: linear-gradient(to top , fade-out(#111, 0.7),55%, fade-out(#111, 1));
		@include media-breakpoint-up(md) {
			background-image: linear-gradient(to top , fade-out(#111, 0.98), 35% , fade-out(#111, 0.5));
		}
	}
	&.dark-vertical{
		background-image: linear-gradient(to top , fade-out(#111, 0.7),55%, fade-out(#111, 1));
		@include media-breakpoint-up(md) {
			background-image: linear-gradient(to top , fade-out(#111, 0.58), 35% , fade-out(#111, 1));
		}
	}
	&.dark-horizontal{
		background-image: linear-gradient(to right , fade-out(#111, 0.7),55%, fade-out(#111, 1));
		@include media-breakpoint-up(md) {
			background-image: linear-gradient(to right , fade-out($brand-info, 0.38), 25% , fade-out($brand-info, 1));
		}
	}
	&.primary-vertical{
		background: fade-out($primary, 0.6);
		background: linear-gradient(to top , fade-out($brand-primary, 0.48), 35% , fade-out($brand-primary, 0.65));
	}
	&.secondary-vertical{
		background-image: linear-gradient(to top , fade-out($secondary, 0.48), 35% , fade-out($secondary, 0.65));
	}
	&.info{
		background-image: linear-gradient(to top , fade-out($brand-info, 0.4), 30%, fade-out($brand-info, 1));
		@include media-breakpoint-up(md) {
      			background-image: linear-gradient(to right , fade-out(desaturate($brand-info, 5%), 0.15), fade-out($brand-info, 0.55));
      		}
	}
	&.overlay-inverse{
		background-image: linear-gradient(to top , fade-out($brand-inverse, 0.4), 30%, fade-out($brand-inverse, 1));
		@include media-breakpoint-up(md) {
      			background-image: linear-gradient(to right , fade-out(desaturate($brand-inverse, 25%), 0.15), fade-out($brand-inverse, 0.55));
      		}
	}
 }



 // 
// MEDIUM ZOOM STYLES
// 

.medium-zoom-overlay{
	z-index: 150;
}

.medium-zoom-image{
	z-index: 152;
}