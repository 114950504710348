/*
 * CSS Page Transitions
 * Don't forget to add vendor prefixes!
 */
.m-scene {
  /** Basic styles for an animated element */
  .scene_element {
    animation-duration: 0.25s;
    transition-timing-function: ease-in;
    //animation-fill-mode: both;
  }

  /** An element that fades in */
  .scene_element--fadein {
    animation-name: fadeIn;
  }

  /** An element that fades in and slides up */
  .scene_element--fadeinup {
    animation-name: fadeInUp;
  }

  /** An element that fades in and slides from the right */
  .scene_element--fadeinright {
    animation-name: fadeInRight;
  }
}