/* ==========================================================================
   Author's custom styles
   ========================================================================== */

   // Import Bootstrap
   @import "../node_modules/bootstrap/scss/functions";
   @import "utils/_variables";
   @import "../node_modules/bootstrap/scss/bootstrap";
   
   // Library's external
   //@import "/bower_components/swiper/dist/css/swiper.css";
   @import "base/typography";
   @import "base/grid";
   @import "base/background-variants";
   @import "base/animations";
   
   @import "layout/layout";
   @import "layout/footer";
   
   //@import "layout/navigation";
   
   
   @import "themes/theme";
   
   @import "components/swiper";
   @import "components/navs";
   @import "components/navbar";
   @import "components/nav-burger";
   @import "components/fullscreen-menu";
   @import "components/full-page-nav";
   
   @import "components/images";
   @import "components/icons";
   @import "components/buttons";
   @import "components/forms";
   @import "components/hover";
   @import "components/page-transitions";
   @import "components/lists";
   @import "components/cards";
   @import "components/next-project";
   @import "components/post-counter";
   
   @import "base/bordered-type";
   
   @import "layout/random-blocks";
   @import "layout/directions";
   
   @import "pages/home";
   
   @import "layout/project";
   @import "layout/images";
   
   @import "pages/post";
   @import "components/intro-animation";
   @import "pages/projects-overview";
   