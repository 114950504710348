
 .image-blocks{
 	list-style: none;
 	margin-left: 0;
 	padding-left: 0;
 	margin-bottom: 0;
 	display: flex;
 }

 .image-block{
 	display: inline-block;
 	margin-right: $spacer;

 	&:last-child{
 		margin-right: 0;
 	}
 }


 //
 // Sizing definitions besides aspect ratio holders
 //


// Full screen height background images using vh's
.bg-image-full-height{
	height: auto;
	min-height: 100vh;
}



 //
 // The default slider is a full width automatic image slider
 //

 .default-swiper{
 	.swiper-slide{
 		width: auto;
 		margin-right: $spacer;
 	}
 }
