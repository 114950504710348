body {
    min-height: 100vh;
    display: flex;
    flex-flow: row wrap;
}

main {
  
}

.main{
    position: relative;
    box-shadow: 0 20px 10px -20px rgba(0,0,0,0.35);
    z-index: 2;
    background: #fff;
    width: 100%;
}

section {
        padding: 6em 0;
    position: relative;
    background: #fff;
}


.overflow-hidden{
    overflow: hidden;
}

.text-center {
    img {
        margin: 0 auto;
    }
}


.story-width{
    max-width: 50rem;
    margin: 0 auto;
}
